/* eslint-disable import/no-unresolved */
import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { PATH_AFTER_LOGIN } from '../utils/enums';

import AuthGuard from '../auth/auth-guard.js';
import LoadingScreen from '../components/loading/LoadingScreen.tsx';
import PrivateLayout from '../layouts/PrivateLayout.tsx';

// ----------------------------------------------------------------------

// MENU
const DesignSystemPage = lazy(() => import('../pages/designSystem/DesignSystem.tsx'));
const DashboardPage = lazy(() => import('../pages/dashboard/Dashboard.tsx'));
const CustomersPage = lazy(() => import('../pages/customers/customer/Page.tsx'));
const CustomerOverviewPage = lazy(() => import('../pages/customers/customer/overview/Page.tsx'));

const AlertsPage = lazy(() => import('../pages/alerts/Page.tsx'));
const AuditTrailsPage = lazy(() => import('../pages/auditTrails/Page.tsx'));
const RemindersPage = lazy(() => import('../pages/reminders/Page.tsx'));
// ----------------------------------------------------------------------

export const privateRoutes = [
  {
    path: '/',
    element: <Navigate to={PATH_AFTER_LOGIN} replace />,
  },

  {
    path: 'design-system',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <DesignSystemPage />, index: true },
      { path: 'design-system', element: <DesignSystemPage /> },
    ],
  },

  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <DashboardPage />, index: true },
      { path: 'dashboard', element: <DashboardPage /> },
    ],
  },
  
  {
    path: 'alerts',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <AlertsPage />, index: true },
      { path: 'alerts', element: <AlertsPage /> },
    ],
  },

  {
    path: 'audit-trails',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <AuditTrailsPage />, index: true },
      { path: 'audit-trails', element: <AuditTrailsPage /> },
    ],
  },

  {
    path: 'reminders',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <RemindersPage />, index: true },
      { path: 'reminders', element: <RemindersPage /> },
    ],
  },
  
  {
    path: 'customers',
    element: (
      <AuthGuard>
        <PrivateLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </PrivateLayout>
      </AuthGuard>
    ),
    children: [
      { element: <CustomersPage />, index: true },
      { path: 'customers', element: <CustomersPage /> },
      { path: 'overview', element: <CustomerOverviewPage /> },
    ],
  },

];
