import _ from 'lodash';

// Array ----------------------------------------------------------------------

export const removeEmptyRows = (array: any = [], fields: any = []) => {
  try {
    return array.map((value: any) => {
      let row = fields.map((f: any) => {
        return value[f]
      }).filter((e: any) => {
        return e === 0 ? '0' : e
      });
      return (row && row.length > 0) ? value : null;
    }).filter((x: any) => x !== null)
  }catch(e){
    return [];
  }
}
export const removeEmptyRowsWithArrays = (arr: any, callback: any = null) => {
  if (arr && arr.length > 0) {
    return arr.filter((row: any) => {
      if (arr && (arr.length > 0) && callback) {
        return callback(row);
      }
    });
  } else {
    return [];
  }
}
export const hasEmptyRows = (arr: any, callback: any = null) => {
  let state = false;

  if (callback && arr && arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      state = callback(arr[i], i);
      if (state)
        break;
    }
  }

  return state;
}
export const hasDuplicated = (array: Array<any> = [], fields: Array<any> = [], texts: Array<any> = []) => {
  let status = false;
  if (texts && texts.length > 0) {
      for (let t = 0; t < texts.length; t++) {
          let text: any = texts[t];

          if (fields && fields.length > 0) {
              for (let f = 0; f < fields.length; f++) {
                  let field = fields[f];

                  let count = array.filter((x: any) => x[field].toString().toLowerCase() === text.toString().toLowerCase()).length;
                  if (count > 0) {
                      status = true;
                      break;
                  }
              }
          }

      }
  }
  return status;
};

export const addToArrayIfNotExist = (arr: Array<any> = [], value: any, item: any = null) => {
  let index = arr.findIndex((x: any) => x.value == value);

  if (index === -1) {
      arr.push(item);
  }

  return arr;
};