import _ from 'lodash';
import { toast } from 'react-toastify';

import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import { ReactComponent as SuccessIcon } from "../../assets/icons/success.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg";
import { ReactComponent as DangerIcon } from "../../assets/icons/danger.svg";

// Toast ----------------------------------------------------------------------

export const info = (title: string|null, desc: string|null = null) => {
  toast.info(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, { icon: <><InfoIcon /></> });
}
export const success = (title: string|null, desc: string|null = null) => {
  toast.success(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, { icon: <><SuccessIcon /></> });
}
export const warning = (title: string|null, desc: string|null = null) => {
  toast.warning(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, { icon: <><WarningIcon /></> });
}
export const danger = (title: string|null, desc: string|null = null) => {
  toast.error(<>
    {title && <div className='title'>{title}</div>}
    {desc && <div>{desc}</div>}
  </>, { icon: <><DangerIcon /></> });
}
