import React from 'react';
import styled from 'styled-components';

import {
  Row,
  Col,
  Image,
} from 'react-bootstrap';

const  DefaultImage = require("../assets/img/default_image.png") as string;

// ----------------------------------------------------------------------

const StyledRow = styled(Row)`
  padding: 36px 48px;
  height: 100px;
`;
const StyledSmallerImage = styled(Image)`
  width: 28px;
  height: 28px;
  margin-left: 8px;
`;
// ----------------------------------------------------------------------

const PublicHeader = () => {
  return <StyledRow id={'header_ID'} className={'g-0 align-items-center'}>
    <Col xs={true}>
      <Row className={'g-2 align-items-center'}>
        <Col xs={'auto'}><StyledSmallerImage src={DefaultImage} roundedCircle /></Col>
        <Col xs={'auto'}><small className={'fw-bold text-primary'}>WastePorter</small></Col>
      </Row>
    </Col>
  </StyledRow>
}

export default PublicHeader;