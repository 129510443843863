import PropTypes from 'prop-types';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { 
    Container,
    Row,
    Col,
} from 'react-bootstrap';

import PrivateHeader from '../sections/PrivateHeader';
import Menu from '../sections/Menu';

import { MAIN_BREAKPOINT } from '../utils/enums';

// ----------------------------------------------------------------------

const StyledContent = styled.div`
  display: flex;
  min-height: calc(100vh - var(--header-height, 60px));

  ${up(MAIN_BREAKPOINT)} {
    display: flex;
  }

  ${down(MAIN_BREAKPOINT)} {
    display: block;
  }
`;
const StyledChildren = styled.div`
  width: 100%;
  overflow: hidden;
  min-height: 300px;
`;

// ----------------------------------------------------------------------

export default function PrivateLayout({ children }: any) {
  const headerHeight = document.getElementById('header_ID')?.clientHeight || 60;
  document.documentElement.style.setProperty('--header-height', `${(headerHeight + 1)}px`);

  return <Container fluid={'xxxl'}>
    <Row>
      <Col xs={12} className={'g-0'}>
        <PrivateHeader />

        <StyledContent>
          <Menu />
          <StyledChildren>{children}</StyledChildren>
        </StyledContent>
      </Col>
    </Row>
  </Container>
}

PrivateLayout.propTypes = {
  children: PropTypes.node,
};
