import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { axios, axiosAuth } from '../../utils/axios';
import { successAPI, errorAPI } from '../../utils/utils';


interface InitState {
  isLoading: boolean,
  user: any,
  userAccount: any,
  userCookies: any,
}

function NewReducer() {
  const name = 'loginSlice';


  const initialState: InitState = {
    isLoading: false,
    user: null,
    userAccount: null,
    userCookies: null,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    
    logout: (state: InitState) => {
      state.isLoading = false;
      state.user = null;
      state.userAccount = null;
      state.userCookies = null;
    },

    
    startDetailsDispatch: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetailsDispatch: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.userAccount = action.payload;
    },

    startDetailsCommonProfile: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetailsCommonProfile: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.user = action.payload;
    },

    startUserCookiesCommonProfile: (state: InitState) => {
      state.isLoading = true;
    },
    finishUserCookiesCommonProfile: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.userCookies = action.payload;
    },

    startLogin: (state: InitState) => {
      state.isLoading = true;
    },
    finishLogin: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.userCookies = action.payload;
    },

    startRegister: (state: InitState) => {
      state.isLoading = true;
    },
    finishRegister: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
  };


  const apis = {
    callDetailsDispatchApi: (callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetailsDispatch());

      await axios.get('account/authenticate-token').then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishDetailsDispatch(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDetailsDispatch(null));
      });
    },

    callDetailsCommonProfileApi: (callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetailsCommonProfile());

      await axiosAuth.get('account/authenticate-token').then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishDetailsCommonProfile(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDetailsCommonProfile(null));
      });
    },
    
    callUserCookiesApi: (callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUserCookiesCommonProfile());

      await axiosAuth.get('usercookiesetting').then(result => {
        let data = result.data;
        
        successAPI(data);

        let obj = (result && result.data && result.data.data) ? result.data.data : null;
        callback(true, obj);
        dispatch(actions.finishUserCookiesCommonProfile(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishUserCookiesCommonProfile(null));
      });
    },

    callLogoutApi: () => async (dispatch: any) => {
      dispatch(actions.logout());
    },

    callLoginApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startLogin());

      await axiosAuth.post('account/login', params).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishLogin(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishLogin(null));
      });
    },
    
    callRegisterApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRegister());

      await axiosAuth.post('account/register', params).then(result => {
        let data = result.data;

        successAPI(data);

        callback(true, data);
        dispatch(actions.finishRegister(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishRegister(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();