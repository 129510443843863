import * as yup from 'yup';
import moment from 'moment';

import { validateCoordinates, dateFormat } from '../utils';


declare module 'yup' {
    interface StringSchema<
      TType extends Maybe<string> = string | undefined,
      TContext extends AnyObject = AnyObject,
      TOut extends TType = TType,
    > extends yup.BaseSchema<TType, TContext, TOut> {
        coordinates(message?: string): StringSchema<TType, TContext>;
        color(message?: string): StringSchema<TType, TContext>;
        runningNumber(message?: string): StringSchema<TType, TContext>;
        maxDate(date?: any, message?: string): StringSchema<TType, TContext>;
        minDate(date?: any, message?: string): StringSchema<TType, TContext>;
    }


    interface ObjectSchema<
      TType extends AnyObject = AnyObject,
      TContext extends AnyObject = AnyObject,
      TOut extends TType = TType
    > extends yup.BaseSchema<TType, TContext, TOut> {
        uniqueFields(fields?: Array<Array<string>>, message?: string): ArraySchema<TType, TContext, TOut>;
    }


    interface ArraySchema<
      T extends any[] = any[],
      C extends AnyObject = AnyObject,
      O extends string = string,
      I extends string = string
    > extends yup.BaseSchema<T, C, O, I> {
        unique(field?: string, message?: string): ArraySchema<TType, TContext, TOut>;
        uniqueFields(fields?: Array<string>, message?: string): ArraySchema<TType, TContext, TOut>;
        hasEmpty(field?: string, message?: string): ArraySchema<TType, TContext, TOut>;
        hasEmptyFields(fields?: Array<string>, message?: string): ArraySchema<TType, TContext, TOut>;
    }
}





//yup.string().coordinates('Invalid coordinates')
yup.addMethod(yup.string, 'coordinates', function (message?: string = 'Invalid coordinates') {
    return this.test('coordinates', message, function (value) {
        let isValid = (value && value != '') ? validateCoordinates(value) : true;
        return isValid;
    });
});

//yup.string().color('Invalid color')
yup.addMethod(yup.string, 'color', function (message?: string = 'Invalid color') {
    return this.test('color', message, function (value) {
        if (!value) return true;
        const hexColorRegex = /^#(?:[0-9a-fA-F]{3}){1}(?:[0-9a-fA-F]{3})?$|^#(?:[0-9a-fA-F]{6}){1}(?:[0-9a-fA-F]{2})?$/;
        return hexColorRegex.test(value);
    });
});

//yup.string().runningNumber('Last char must be a number')
yup.addMethod(yup.string, 'runningNumber', function (message?: string = 'Last char must be a number') {
    return this.test('runningNumber', message, function (value) {
        if (value && value.length > 0) {
            const lastCharacter = value.charAt(value.length - 1);
            return /\d/.test(lastCharacter);
        }
        return true;
    });
});

//yup.string().maxDate(new Date(), 'Date cannot be greater than')
yup.addMethod(yup.string, 'maxDate', function (date: any = new Date(), message?: string = 'Date cannot be greater than') {
    return this.test('maxDate', message + ' ' + moment(date).format(dateFormat()), function (value) {
        if (!value) return true;

        const dateObj = moment(date).format(dateFormat());
        const valueDate = moment(value, dateFormat());
        const maxDate = moment(dateObj, dateFormat());

        return moment(valueDate).isSameOrBefore(moment(maxDate));
    });
});

//yup.string().minDate(new Date(), 'Date cannot be less than')
yup.addMethod(yup.string, 'minDate', function (date: any = new Date(), message?: string = 'Date cannot be less than') {
    return this.test('minDate', message + ' ' + moment(date).format(dateFormat()), function (value) {
        if (!value) return true;

        const dateObj = moment(date).format(dateFormat());
        const valueDate = moment(value, dateFormat()).toDate();
        const minDate = moment(dateObj, dateFormat()).toDate();

        return moment(valueDate).isSameOrAfter(moment(minDate));
    });
});





//yup.object().uniqueFields([['field11', 'field12', 'arr.field13'], ['field21', 'field22', 'arr.field23']], 'There are duplicates')
yup.addMethod(yup.object, 'uniqueFields', function (fields?: Array<Array<string>>, message?: any = 'There are duplicates') {
    return this.test('uniqueFields', message, function (value: any) {
        if (!fields || fields.length === 0) {
            return true;
        }

        for (const fieldGroup of fields) {
            const values: any = [];

            for (const field of fieldGroup) {
                const fieldParts = field.split('.');
                if (Array.isArray(value[fieldParts[0]])) {
                    value[fieldParts[0]].forEach((item: any) => {
                        const nestedValue = item[fieldParts[fieldParts.length - 1]];
                        if (nestedValue !== null && nestedValue !== undefined && nestedValue !== '') {
                            values.push(nestedValue);
                        }
                    });
                } else {
                    if (value[fieldParts[0]] !== null && value[fieldParts[0]] !== undefined && value[fieldParts[0]] !== '') {
                        values.push(value[fieldParts[0]]);
                    }
                }
            }

            if (values.length !== new Set(values).size) {
                return this.createError({
                    path: 'form',
                    message: message,
                });
            }
        }

        return true;
    });
});





//yup.array().of(yup.object()).unique('field, 'There are duplicates')
yup.addMethod(yup.array, 'unique', function (field?: string, message?: any = 'There are duplicates') {
    return this.test('unique', message, function (values: any) {
        const mappedValues = values.map((value: any) => !(value[field] === null || value[field] === undefined || value[field] === '') && value[field]);
        return mappedValues.length === new Set(mappedValues).size;
    });
});

//yup.array().of(yup.object()).uniqueFields(['field1', 'field2'], 'There are duplicates')
yup.addMethod(yup.array, 'uniqueFields', function (fields?: Array<string>, message?: any = 'There are duplicates') {
    return this.test('uniqueFields', message, function (values: any) {
        const uniqueValues = values.map((value: any) => {
            return fields.map(field => !(value[field] === null || value[field] === undefined || value[field] === '') && value[field]);
        });
        const flattenedValues = [].concat(...uniqueValues);
        return flattenedValues.length === new Set(flattenedValues).size;
    });
});


//yup.array().of(yup.object()).hasEmpty('field', 'There are empty fields')
yup.addMethod(yup.array, 'hasEmpty', function (field?: string, message?: any = 'There are empty fields') {
    return this.test('hasEmpty', message, function (values: any) {
        const hasEmpty = values.some((x: any) => x[field] === null || x[field] === undefined || x[field] === '');
        return (values.length == 1) ? true : !hasEmpty;
    });
});

//yup.array().of(yup.object()).hasEmptyFields(['field1', 'field2'], 'There are empty fields')
yup.addMethod(yup.array, 'hasEmptyFields', function (fields?: Array<string>, message?: any = 'There are empty fields') {
    return this.test('hasEmptyFields', message, function (values: any) {
        if(values.length == 1){
            const hasEmpty = fields.every(field => values[0][field] === null || values[0][field] === undefined || values[0][field] === '');
            const hasNonEmpty = fields.every(field => values[0][field] !== null && values[0][field] !== undefined && values[0][field] !== '');
            return (hasEmpty || hasNonEmpty);

        } else {
            const hasEmpty = values.some((x: any) => {
                return fields.some(field => x[field] === null || x[field] === undefined || x[field] === '');
            });
            return !hasEmpty;
        }
    });
});